import * as React from "react";
import Container from "./styles";
import type { TypeTableCellProps } from "./TableCellTypes";

/**
 * The table cell component is for rendering table cells and is meant to be used with the table component.
 */
export class TableCell extends React.Component<TypeTableCellProps> {
  static displayName: string;
  override render() {
    const {
      id,
      content,
      colSpan,
      width,
      align,
      scope,
      children,
      color,
      ...rest
    } = this.props;
    return (
      <Container
        {...rest}
        // If the `scope` property is passed we must render the element as a <th>
        as={scope ? "th" : "td"}
        scope={scope ? scope : undefined}
        alignment={align || "left"}
        key={id}
        colSpan={colSpan}
        width={width}
        data-qa-table-cell=""
        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={color}
      >
        {children || content}
      </Container>
    );
  }
}

export default TableCell;
