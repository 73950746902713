import type { PropsWithChildren, ReactNode, ComponentProps } from "react";
import {
  toast as toastifyToast,
  cssTransition,
  type ToastContent,
} from "react-toastify";
import Box from "@sproutsocial/seeds-react-box";
import Icon, { type TypeIconName } from "@sproutsocial/seeds-react-icon";
import Text from "@sproutsocial/seeds-react-text";
import { Container, ToastRoot } from "./styles";
import type { TypeToastOptions, TypeToastTheme } from "./ToastTypes";
import styled from "styled-components";

export const toastDismiss: typeof toastifyToast.dismiss = (...input) =>
  // @ts-ignore Not sure what this type is supposed to be
  toastifyToast.dismiss(...input);
export const toastIsActive: typeof toastifyToast.isActive = (...input) =>
  toastifyToast.isActive(...input);
export const toastUpdate: typeof toastifyToast.update = (...input) =>
  toastifyToast.update(...input);

const NoTransition = cssTransition({
  enter: "SproutToast__none-in",
  exit: "SproutToast__none-out",
});
const SproutZoomTransition = cssTransition({
  enter: "SproutToast__zoom-in",
  exit: "SproutToast__zoom-out",
});

export const ToastContainer = (
  props: Partial<ComponentProps<typeof ToastRoot>>
) => <ToastRoot {...props} />;

const themeIcon: Record<TypeToastTheme, TypeIconName> = {
  success: "circle-check-outline",
  info: "circle-i-outline",
  warning: "triangle-exclamation-outline",
  error: "triangle-exclamation-outline",
} as const;

export function toast<TData = unknown>(
  options: TypeToastOptions<TData>
): ReturnType<typeof toastifyToast> {
  const {
    closeOnClick = true,
    content,
    onClose,
    persist,
    toastId: inputToastId,
    useTransition = true,
    position = "bottom-right",
    autoClose = persist ? false : 6000,
    transition = useTransition ? SproutZoomTransition : NoTransition,
    ...rest
  } = options;

  let toastId = inputToastId;
  if (!toastId && typeof content === "string") {
    toastId = content;
  }

  const renderToast: ToastContent<TData> = (toastInput) => {
    const renderedContent =
      typeof content === "function" ? content(toastInput) : content;

    if (options.theme === "custom") {
      return renderedContent;
    }

    const theme = options.theme || "info";
    const iconName = options.icon || themeIcon[theme];
    const containerColor =
      options.color ||
      (
        {
          success: "container.border.success",
          error: "container.border.error",
          info: "container.border.info",
          warning: "container.border.warning",
        } as const
      )[theme];
    const iconColor =
      options.color ||
      (
        {
          success: "icon.success",
          error: "icon.error",
          info: "icon.info",
          warning: "icon.warning",
        } as const
      )[theme];

    return (
      // TODO: if this closes when clicked, there should be a label saying "Click to close" that can be overridden
      <ToastContentContainer
        icon={<Icon name={iconName} color={iconColor} />}
        close={<Icon name="x-outline" color="icon.base" aria-hidden />}
        highlightColor={containerColor}
      >
        {renderedContent}
      </ToastContentContainer>
    );
  };

  const toastOptions = {
    autoClose,
    closeOnClick,
    onClose,
    toastId: toastId || undefined,
    transition,
    position,
    ...rest,
    icon: undefined,
    color: undefined,
  } as const;

  if (toastId && toastIsActive(toastId)) {
    toastifyToast.update<TData>(toastId, {
      ...toastOptions,
      render: renderToast,
    });
  } else {
    toastId = toastifyToast<TData>(renderToast, toastOptions);
  }

  return toastId;
}

export default ToastContainer;

export const ToastContentContainer = ({
  children,
  icon,
  close,
  highlightColor,
}: PropsWithChildren<{
  /**
   * A ReactNode in the icon slot
   */
  icon: ReactNode;
  /**
   * A ReactNode in the close button slot
   */
  close: ReactNode;
  highlightColor?: ComponentProps<typeof Box>["bg"];
}>) => {
  return (
    <Container data-qa-toast="">
      {highlightColor ? (
        <ToastHighlight bg={highlightColor} aria-hidden />
      ) : null}

      <Box css="line-height: 1;">{icon}</Box>

      <Box flex={1}>
        <Text as="div" color="text.body" data-qa-toast-content="">
          {children}
        </Text>
      </Box>

      <Box css="line-height: 1;">{close}</Box>
    </Container>
  );
};

export const ToastHighlight = styled(Box)<ComponentProps<typeof Box>>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
`;
