import * as React from "react";
import { mergeRefs } from "@sproutsocial/seeds-react-utilities";
import { useTextContent } from "@sproutsocial/seeds-react-hooks";
import Box from "@sproutsocial/seeds-react-box";
import Icon from "@sproutsocial/seeds-react-icon";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";
import Container from "./styles";
import type { TypeTokenProps } from "./TokenTypes";

const Token = ({
  children,
  closeable = true,
  onClick,
  qa,
  valid = true,
  hasWarning = false,
  disabled = false,
  palette = "neutral",
  innerRef = null,
  ...rest
}: TypeTokenProps) => {
  const textContainer = useTextContent("");

  return (
    <Container
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore some legacy ref type mismatch between divs
      ref={mergeRefs(textContainer, innerRef)}
      valid={valid}
      hasWarning={hasWarning}
      palette={palette}
      type={closeable || onClick ? "button" : undefined}
      as={closeable || onClick ? "button" : "div"}
      closeable={closeable || !!onClick}
      disabled={disabled}
      onClick={onClick}
      data-qa-token={textContainer.current}
      data-qa-token-iscloseable={closeable === true}
      data-qa-token-isvalid={valid === true}
      data-qa-token-isdisabled={disabled === true}
      {...qa}
      {...rest}
    >
      {!valid && (
        <>
          <VisuallyHidden as="div" role="status">
            This is an invalid token
          </VisuallyHidden>
          <Icon
            aria-hidden
            pr={300}
            name="circle-exclamation-solid"
            size="mini"
          />
          <VisuallyHidden>Invalid</VisuallyHidden>
        </>
      )}
      {closeable ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box as="span" display="flex" alignItems="center">
            {children}
          </Box>
          <Icon aria-hidden pl={300} name="x-solid" size="mini" />
        </Box>
      ) : (
        children
      )}
    </Container>
  );
};

export default Token;
