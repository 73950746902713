function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable no-restricted-imports */
import { transparentize } from "polished";
import COLORS from "@sproutsocial/seeds-color";
import DEPTH from "@sproutsocial/seeds-depth";
// json-to-scss is not working with the alias right now
//import {theme as lightTheme} from '@src';
//import {TypeThemeMode} from '@src/types/theme';
import lightTheme from "../light/theme";
import { datavizPalette } from "./dataviz-palette";
import { green, blue, purple, yellow, orange, red, neutral, magenta, pink, aqua, teal } from "./decorative-palettes";
var MODE = "dark";
export var shadows = {
  low: "".concat(DEPTH.ELEVATION_LEVEL_100, " ").concat(COLORS.COLOR_NEUTRAL_1100, "FF"),
  medium: "".concat(DEPTH.ELEVATION_LEVEL_300, " ").concat(COLORS.COLOR_NEUTRAL_1100, "FF"),
  high: "".concat(DEPTH.ELEVATION_LEVEL_400, " ").concat(COLORS.COLOR_NEUTRAL_1100, "FF")
};

// If you are making changes to the colors in the theme file tag the Design Systems team on your PR! Thank you!!

var colors = _objectSpread(_objectSpread({}, lightTheme.colors), {}, {
  app: {
    background: {
      base: COLORS.COLOR_NEUTRAL_1000
    }
  },
  container: {
    background: {
      base: COLORS.COLOR_NEUTRAL_900,
      success: green.background,
      warning: yellow.background,
      error: red.background,
      info: blue.background,
      opportunity: purple.background,
      /**  @deprecated Use "error" instead of "danger" */
      danger: red.background,
      decorative: {
        green: green.background,
        blue: blue.background,
        purple: purple.background,
        yellow: yellow.background,
        orange: orange.background,
        red: red.background,
        neutral: neutral.background,
        magenta: magenta.background,
        pink: pink.background,
        aqua: aqua.background,
        teal: teal.background
      },
      selected: COLORS.COLOR_NEUTRAL_0,
      positive_sentiment: COLORS.COLOR_BLUE_500,
      negative_sentiment: COLORS.COLOR_RED_500,
      neutral_sentiment: COLORS.COLOR_NEUTRAL_300
    },
    border: {
      base: COLORS.COLOR_NEUTRAL_1100,
      success: green.highlight,
      warning: yellow.highlight,
      error: red.highlight,
      /**  @deprecated Use "error" instead of "danger" */
      danger: red.highlight,
      info: blue.highlight,
      opportunity: purple.highlight,
      decorative: {
        green: green.highlight,
        blue: blue.highlight,
        purple: purple.highlight,
        yellow: yellow.highlight,
        orange: orange.highlight,
        red: red.highlight,
        neutral: neutral.highlight,
        magenta: magenta.highlight,
        pink: pink.highlight,
        aqua: aqua.highlight,
        teal: teal.highlight
      },
      selected: COLORS.COLOR_NEUTRAL_0
    }
  },
  button: {
    primary: {
      background: {
        base: COLORS.COLOR_BLUE_400,
        hover: COLORS.COLOR_BLUE_300,
        active: COLORS.COLOR_BLUE_200
      },
      border: {
        base: "transparent"
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_900,
        hover: COLORS.COLOR_NEUTRAL_1000
      }
    },
    secondary: {
      background: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_100,
        active: COLORS.COLOR_NEUTRAL_0
      },
      border: {
        base: COLORS.COLOR_NEUTRAL_0
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_0,
        hover: COLORS.COLOR_NEUTRAL_800
      }
    },
    pill: {
      background: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_1000,
        active: COLORS.COLOR_NEUTRAL_900
      },
      border: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_0
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_100,
        hover: COLORS.COLOR_NEUTRAL_0
      }
    },
    destructive: {
      background: {
        base: COLORS.COLOR_RED_400,
        hover: COLORS.COLOR_RED_300,
        active: COLORS.COLOR_RED_200
      },
      border: {
        base: "transparent"
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_900,
        hover: COLORS.COLOR_NEUTRAL_1000
      }
    },
    placeholder: {
      background: {
        base: "transparent",
        hover: COLORS.COLOR_NEUTRAL_1100,
        active: COLORS.COLOR_NEUTRAL_1100
      },
      border: {
        base: COLORS.COLOR_NEUTRAL_500
      },
      text: {
        base: COLORS.COLOR_BLUE_400,
        hover: COLORS.COLOR_BLUE_300
      }
    },
    unstyled: {
      background: {
        base: "transparent"
      },
      border: {
        base: "transparent"
      },
      text: {
        base: COLORS.COLOR_NEUTRAL_300,
        hover: COLORS.COLOR_NEUTRAL_200
      }
    }
  },
  link: {
    base: COLORS.COLOR_BLUE_400,
    hover: COLORS.COLOR_BLUE_300
  },
  text: {
    headline: COLORS.COLOR_NEUTRAL_0,
    subtext: COLORS.COLOR_NEUTRAL_200,
    body: COLORS.COLOR_NEUTRAL_100,
    inverse: COLORS.COLOR_NEUTRAL_900,
    error: red.foreground,
    background: {
      highlight: COLORS.COLOR_YELLOW_900,
      selection: COLORS.COLOR_BLUE_800
    },
    decorative: {
      green: green.foreground,
      blue: blue.foreground,
      purple: purple.foreground,
      yellow: yellow.foreground,
      orange: orange.foreground,
      red: red.foreground,
      neutral: neutral.foreground,
      magenta: magenta.foreground,
      pink: pink.foreground,
      aqua: aqua.foreground,
      teal: teal.foreground
    }
  },
  icon: {
    base: COLORS.COLOR_NEUTRAL_100,
    inverse: COLORS.COLOR_NEUTRAL_900,
    success: green.foreground,
    warning: yellow.foreground,
    error: red.foreground,
    /**  @deprecated Use "error" instead of "danger" */
    danger: red.foreground,
    info: blue.foreground,
    opportunity: purple.foreground,
    applied: COLORS.COLOR_BLUE_400,
    positive_sentiment: COLORS.COLOR_BLUE_600,
    negative_sentiment: COLORS.COLOR_RED_600,
    neutral_sentiment: COLORS.COLOR_NEUTRAL_500
  },
  form: {
    background: {
      base: COLORS.COLOR_NEUTRAL_900,
      selected: COLORS.COLOR_NEUTRAL_0
    },
    border: {
      base: COLORS.COLOR_NEUTRAL_500,
      error: red.highlight,
      warning: yellow.highlight,
      selected: COLORS.COLOR_NEUTRAL_0
    },
    placeholder: {
      base: COLORS.COLOR_NEUTRAL_500
    }
  },
  listItem: {
    background: {
      base: "transparent",
      hover: COLORS.COLOR_NEUTRAL_800,
      selected: COLORS.COLOR_NEUTRAL_0,
      active: COLORS.COLOR_NEUTRAL_0
    },
    border: {
      base: neutral.highlight
    }
  },
  menuItem: {
    background: {
      active: COLORS.COLOR_NEUTRAL_700
    }
  },
  overlay: {
    background: {
      base: transparentize(0.28, COLORS.COLOR_NEUTRAL_1000)
    },
    text: {
      base: COLORS.COLOR_NEUTRAL_0
    },
    icon: {
      base: COLORS.COLOR_NEUTRAL_0
    }
  },
  elevation: {
    base: COLORS.COLOR_NEUTRAL_1100
  },
  illustration: {
    fill: COLORS.COLOR_BLUE_400,
    stroke: neutral.foreground
  },
  dataviz: {
    map: datavizPalette.DATAVIZ_COLORS_MAP,
    list: datavizPalette.DATAVIZ_COLORS_LIST,
    placeholder: datavizPalette.PLACEHOLDER
  },
  userStatus: {
    online: COLORS.COLOR_GREEN_500,
    offline: COLORS.COLOR_RED_500,
    unavailable: COLORS.COLOR_NEUTRAL_500
  }
}, datavizPalette);
var darkTheme = _objectSpread(_objectSpread({}, lightTheme), {}, {
  colors: colors,
  shadows: shadows,
  mode: MODE
});
export default darkTheme;