import * as React from "react";
import TableCell from "./TableCell";
import TableHeaderCell from "./TableHeaderCell";
import Container from "./styles";
import type {
  TypePassthroughProps,
  TypeTableProps,
  TypeTableRow,
} from "./TableTypes";

const renderTableRow = (row: TypeTableRow) => {
  return (
    <tbody key={row.id} data-qa-table-row="">
      <tr>
        {row.cells.map((td) => {
          return <TableCell {...td} key={td.id} />;
        })}
      </tr>
    </tbody>
  );
};

/**
 * The table component assist in rendering tablular data.
 */
export const Table = ({
  head = [],
  rows = [],
  onSort,
  sortId,
  sortDirection,
  rowRender,
  children,
  color,
  ...rest
}: TypeTableProps) => {
  if (children) {
    return (
      <Container {...rest} data-qa-table="">
        {children}
      </Container>
    );
  }

  return (
    <Container
      {...rest}
      data-qa-table=""
      // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
    >
      <colgroup>
        {head.map(({ id, colSpan = 1 }) => (
          <col key={id} span={colSpan} />
        ))}
      </colgroup>
      {head.length > 0 && (
        <thead data-qa-table-header="">
          <tr>
            {head.map(({ color, ...th }) => {
              return (
                <TableHeaderCell
                  {...th}
                  key={th.id}
                  onSort={onSort}
                  sortId={sortId}
                  sortDirection={sortDirection}
                  // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  color={color}
                />
              );
            })}
          </tr>
        </thead>
      )}
      {rows.map((row) => {
        return rowRender ? rowRender(row) : renderTableRow(row);
      })}
    </Container>
  );
};

export const TableHead = ({ children, ...props }: TypePassthroughProps) => (
  <thead {...props}>{children}</thead>
);

export const TableBody = ({ children, ...props }: TypePassthroughProps) => (
  <tbody {...props}>{children}</tbody>
);

export const TableRow = ({ children, ...props }: TypePassthroughProps) => (
  <tr {...props}>{children}</tr>
);

TableHead.displayName = "Table.TableHead";
TableBody.displayName = "Table.TableBody";
TableRow.displayName = "Table.TableRow";
TableHeaderCell.displayName = "Table.HeaderCell";
TableCell.displayName = "Table.Cell";

Table.TableHead = TableHead;
Table.TableBody = TableBody;
Table.TableRow = TableRow;
Table.HeaderCell = TableHeaderCell;
Table.Cell = TableCell;

export default Table;
