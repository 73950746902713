import styled, { css } from "styled-components";
import { disabled, focusRing } from "@sproutsocial/seeds-react-mixins";
import Box from "@sproutsocial/seeds-react-box";
import { StyledSwitchButton as Switch } from "@sproutsocial/seeds-react-switch";
export var MenuItemContainer = styled(Box).withConfig({
  displayName: "styles__MenuItemContainer",
  componentId: "sc-168zlb1-0"
})(["", ";width:100%;border-radius:", ";background-color:", ";border:none;text-align:left;color:", ";font-family:", ";font-weight:", ";padding:", ";list-style-type:none;outline:0;", ";", " ", " ", "{", "}", " ", " ", ""], function (props) {
  return !props.hidden && css(["display:block;"]);
}, function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.theme.colors.listItem.background.base;
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontWeights.normal;
}, function (props) {
  return "6px ".concat(props.theme.space[300]);
}, function (props) {
  return css(["", ";"], props.theme.typography[200]);
}, function (props) {
  return props.selected && !props.isCheckboxOrRadio && css(["font-weight:", ";"], function (props) {
    return props.theme.fontWeights.semibold;
  });
}, function (props) {
  return props.active && !props.disabled && css(["color:", ";background-color:", ";.Icon-svg{color:", ";}"], function (props) {
    return props.theme.colors.text.inverse;
  }, props.theme.colors.listItem.background.selected, function (props) {
    return props.theme.colors.text.inverse;
  });
}, Switch, function (props) {
  return props.active && !props.disabled && css(["border-color:", ";"], function (props) {
    return props.theme.colors.container.border.base;
  });
}, function (props) {
  return !props.disabled && css(["&:focus,&:hover{color:", ";background-color:", ";.Icon-svg{color:unset;}}"], function (props) {
    return props.theme.colors.text.body;
  }, function (props) {
    return props.theme.colors.listItem.background.hover;
  });
}, function (props) {
  return props.interactive && !props.disabled ? css(["&:hover{cursor:pointer;}"]) : css(["&:hover{cursor:default;}"]);
}, function (props) {
  return props.disabled && disabled;
});
export var MenuItemsContainer = styled(Box).withConfig({
  displayName: "styles__MenuItemsContainer",
  componentId: "sc-168zlb1-1"
})(["list-style-type:none;&:focus{", "}& > div[role=\"group\"]:first-of-type{border-top:none;}"], focusRing);