import uniqueId from "lodash.uniqueid";
import type { TypeTokenSpec } from "./";

export const asTokenSpec = (text: string): TypeTokenSpec => ({
  id: uniqueId(`${text}-`),
  value: text.trim(),
});

const KeyNameToRegExpChar: { [key: string]: string } = {
  Enter: "\\n",
};

export const delimitersAsRegExp = (delimiters: string[] | null | undefined) => {
  if (!delimiters) return /[,\n]/;
  const chars = delimiters
    .map(
      (key) =>
        KeyNameToRegExpChar[key as keyof typeof KeyNameToRegExpChar] || key
    )
    .join("");
  return RegExp(`[${chars}]`);
};
