import styled from "styled-components";
import { visuallyHidden } from "@sproutsocial/seeds-react-mixins";
import type { ComponentPropsWithRef } from "react";

const StyledVisuallyHidden = styled.span`
  ${visuallyHidden}
`;

export type VisuallyHiddenProps = ComponentPropsWithRef<
  typeof StyledVisuallyHidden
>;

// This wrapper component is needed for react-docgen to work.
// It has issues with generating docs for the styled component directly.
export const VisuallyHidden = (props: VisuallyHiddenProps) => {
  return <StyledVisuallyHidden {...props} />;
};
