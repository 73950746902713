import * as React from "react";
import Container, { TabItem, TabButton } from "./styles";
import type { TypeTabButtonsProps, TypeTabsProps } from "./TabsTypes";

interface TypeTabButtonRef {
  current: null | React.ElementRef<"button">;
}
interface TypeTabButtonContext {
  onActivate: (arg0: string) => void;
  onTabMount: (id: string, ref: TypeTabButtonRef) => void;
  onTabUpdate: (previousId: string, nextId: string) => void;
  onTabUnmount: (id: string) => void;
  selectedId: string;
  fullWidth?: boolean;
}

const TabButtonContext = React.createContext<Partial<TypeTabButtonContext>>({});

class TabItemButton extends React.Component<TypeTabButtonsProps> {
  static override contextType = TabButtonContext;
  // @ts-notes - using the legacy syntax here because `declare` does not play well with babel
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  context!: React.ContextType<typeof TabButtonContext>;

  buttonRef: TypeTabButtonRef = React.createRef<React.ElementRef<"button">>();

  override componentDidMount() {
    this.context.onTabMount?.(this.props.id, this.buttonRef);
  }

  override componentDidUpdate(prevProps: TypeTabButtonsProps) {
    if (prevProps.id !== this.props.id) {
      this.context.onTabUpdate?.(prevProps.id, this.props.id);
    }
  }

  override componentWillUnmount() {
    this.context.onTabUnmount?.(this.props.id);
  }

  override render() {
    const { children, id, ...rest } = this.props;
    const { selectedId, onActivate, fullWidth } = this.context;
    const isSelected = selectedId === id;

    return (
      <TabItem key={id} fullWidth={fullWidth} isSelected={isSelected}>
        <TabButton
          innerRef={this.buttonRef}
          id={id}
          onClick={() => onActivate?.(id)}
          isSelected={isSelected}
          tabIndex={isSelected ? 0 : -1}
          fullWidth={fullWidth}
          data-qa-tab-button={id}
          data-qa-tab-button-state={isSelected}
          aria-selected={isSelected}
          role="tab"
          // TODO: Add a TabPanel subcomponent for use with tabs
          // aria-controls={tabPanelId}
          {...rest}
        >
          {children}
        </TabButton>
      </TabItem>
    );
  }
}

/**
 * Render a group of buttons in a tab-heading style
 */
class Tabs extends React.Component<TypeTabsProps> {
  static Button = TabItemButton;
  buttonRefs: Record<string, TypeTabButtonRef | null | undefined> = {};
  tabList: string[] = [];

  getSelectedId = () => this.props.selectedId;
  onActivate = (id: string) => this.props.onSelect(id);
  onTabMount = (id: string, ref: TypeTabButtonRef) => {
    if (!this.tabList.includes(id)) {
      this.tabList.push(id);
      this.buttonRefs[id] = ref;
    }
  };

  onTabUpdate = (previousId: string, newId: string) => {
    if (this.tabList.includes(previousId)) {
      this.tabList = this.tabList.map((id) => (id === previousId ? newId : id));
      this.buttonRefs[newId] = this.buttonRefs[previousId];
      this.buttonRefs[previousId] = undefined;
    }
  };

  onTabUnmount = (id: string) => {
    if (this.tabList.includes(id)) {
      this.tabList = this.tabList.filter((currentId) => currentId !== id);
      this.buttonRefs[id] = undefined;
    }
  };

  selectNextTab = (id: string) => {
    const buttonRef = this.buttonRefs[id];
    this.props.onSelect(id);
    buttonRef &&
      buttonRef.current &&
      buttonRef.current.focus &&
      buttonRef.current.focus();
  };

  keyHandler = ({ keyCode }: React.KeyboardEvent<HTMLUListElement>) => {
    switch (keyCode) {
      // left arrow
      case 37:
        this.tabList.forEach((id, index) => {
          if (id === this.getSelectedId()) {
            const count = this.tabList.length;
            const nextIndex = index - 1 >= 0 ? index - 1 : count - 1;
            const nextId = this.tabList[nextIndex];
            this.selectNextTab(nextId ? nextId : "");
          }
        });
        break;

      // right arrow
      case 39:
        this.tabList.forEach((id, index) => {
          if (id === this.getSelectedId()) {
            const count = this.tabList.length;
            const nextIndex = index + 1 < count ? index + 1 : 0;
            const nextId = this.tabList[nextIndex];
            this.selectNextTab(nextId ? nextId : "");
          }
        });
        break;

      default:
        break;
    }
  };

  override render() {
    const { children, qa, onSelect, ...rest } = this.props;
    return (
      <Container
        data-qa-tabs=""
        onKeyUp={this.keyHandler}
        onSelect={onSelect}
        role="tablist"
        {...qa}
        {...rest}
      >
        <TabButtonContext.Provider
          value={{
            selectedId: this.getSelectedId(),
            fullWidth: this.props.fullWidth,
            onActivate: this.onActivate,
            onTabMount: this.onTabMount,
            onTabUpdate: this.onTabUpdate,
            onTabUnmount: this.onTabUnmount,
          }}
        >
          {children}
        </TabButtonContext.Provider>
      </Container>
    );
  }
}

export default Tabs;
