import * as React from "react";
import Icon from "@sproutsocial/seeds-react-icon";
import Container from "./styles";
import type { TypeIndicatorProps } from "./IndicatorTypes";

export const Indicator = ({ tooltip = "", ...rest }: TypeIndicatorProps) => {
  return (
    <Container
      data-tip={tooltip}
      data-qa-indicator=""
      aria-label={tooltip}
      tabIndex={0}
      {...rest}
    >
      {tooltip && <div>{tooltip}</div>}
      <Icon name="circle-fill-solid" size="mini" aria-hidden />
    </Container>
  );
};
