import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import type { TypeTableHeaderCellProps } from "./TableHeaderCellTypes";

const Container = styled.th<{
  sortable?: boolean;
  alignment: TypeTableHeaderCellProps["align"];
  width: TypeTableHeaderCellProps["width"];
}>`
  ${(props) => props.theme.typography[100]}
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  padding: ${(props) => props.theme.space[300]};
  text-align: ${(props) => props.alignment};

  ${(props) =>
    props.sortable &&
    css`
      position: relative;
      cursor: pointer;
    `}

  ${COMMON}
`;

export const SortIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  padding: 0 ${(props) => props.theme.space[200]};
`;

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
`;

export default Container;
