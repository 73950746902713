import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";

const Container = styled.tbody`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.container.border.base};

  ${COMMON}
`;

export const Detail = styled.tr<{ isExpanded: boolean }>`
  display: none;

  ${(props) =>
    props.isExpanded &&
    css`
      display: table-row;
    `}
`;

export const Trigger = styled.div`
  cursor: pointer;
`;

export default Container;
