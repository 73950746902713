import React from "react";
import { useEffect, useRef, useState } from "react";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";
import type { TypeTokenInputProps } from "./";

function usePrevious(value: TypeTokenInputProps["tokens"]) {
  const ref = useRef<TypeTokenInputProps["tokens"]>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export const TokenScreenReaderStatus = ({
  tokens,
}: {
  tokens: TypeTokenInputProps["tokens"];
}) => {
  const prevTokens = usePrevious(tokens);
  const [tokenStatus, setTokenStatus] = useState("");

  // TODO: Use callbacks so consumers can pass localized messaging to the screen reader
  useEffect(() => {
    if (prevTokens && tokens) {
      if (prevTokens.length > tokens.length) {
        setTokenStatus(
          `${
            prevTokens.filter((item) => tokens.indexOf(item) === -1)[0]?.value
          } has been removed`
        );
      }

      if (prevTokens.length < tokens.length) {
        setTokenStatus(`${tokens[tokens.length - 1]?.value} has been added.`);
      }
    }
  }, [prevTokens, tokens, tokenStatus]);

  return (
    <VisuallyHidden as="div" role="status">
      {tokenStatus}
    </VisuallyHidden>
  );
};
