import styled from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import type { TypeTableCellProps } from "./TableCellTypes";

const Container = styled.td<{
  alignment: TypeTableCellProps["align"];
}>`
  ${(props) => props.theme.typography[200]}
  font-weight: ${(props) => props.theme.fontWeights.normal};
  padding: ${(props) => props.theme.space[300]};
  text-align: ${(props) => props.alignment};

  ${COMMON}
`;

export default Container;
