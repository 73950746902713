import React, { useCallback, useState, type KeyboardEvent } from "react";
import moment from "moment";
import DayPickerRangeController from "react-dates/lib/components/DayPickerRangeController";
import { ReactDatesCssOverrides } from "../styles";
import {
  commonDatePickerProps,
  DefaultSetStatusText,
  getVisibleMonths,
} from "../common";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";
import type { TypeDateRangePickerProps } from "./DateRangePickerTypes";

const DateRangePicker = ({
  startDate = null,
  endDate = null,
  onDatesChange,
  setStatusText = DefaultSetStatusText,
  initialVisibleMonth,
  numberOfMonths = 2,
  onFocusChange,
  onBlur,
  focusedInput,
  ...rest
}: TypeDateRangePickerProps) => {
  const [statusText, updateStatusText] = useState(() =>
    setStatusText(
      getVisibleMonths(
        moment(initialVisibleMonth?.() ?? startDate ?? undefined),
        numberOfMonths
      )
    )
  );
  interface HandleMonthClick {
    (month: moment.Moment): void;
  }

  const handleMonthClick: HandleMonthClick = useCallback(
    (month) => {
      updateStatusText(setStatusText(getVisibleMonths(month, numberOfMonths)));
    },
    [numberOfMonths, setStatusText]
  );
  const wrappedOnBlur = useCallback<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: undefined | KeyboardEvent<HTMLDivElement>) => void
  >(
    (event) => {
      // for some reason onBlur is called with no event on day selection 🤷
      if (!event) {
        return;
      }
      onFocusChange?.(null);
      onBlur?.(event);
    },
    [onBlur, onFocusChange]
  );

  return (
    <>
      <ReactDatesCssOverrides />
      <VisuallyHidden>
        <div role="status">{statusText}</div>
      </VisuallyHidden>
      <DayPickerRangeController
        {...commonDatePickerProps}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        numberOfMonths={numberOfMonths}
        initialVisibleMonth={initialVisibleMonth || null}
        focusedInput={focusedInput}
        isFocused={focusedInput !== null}
        onBlur={wrappedOnBlur}
        onFocusChange={onFocusChange}
        onPrevMonthClick={handleMonthClick}
        onNextMonthClick={handleMonthClick}
        {...rest}
      />
    </>
  );
};

export default DateRangePicker;
