import * as React from "react";
import Icon from "@sproutsocial/seeds-react-icon";
import Container, { Arrow } from "./styles";
import type { TypeSelectProps } from "./SelectTypes";

const Select = ({
  id,
  name,
  children,
  defaultValue,
  value,
  required,
  isInvalid,
  onChange,
  autoFocus,
  disabled,
  ariaLabel,
  ariaDescribedby,
  size = "default",
  qa = {},
  inputProps = {},
  ...rest
}: TypeSelectProps) => {
  const handleChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Container invalid={isInvalid} disabled={disabled} size={size} {...rest}>
      <select
        id={id}
        name={name}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        defaultValue={defaultValue}
        value={value}
        required={required}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={handleChange}
        data-qa-select={name || ""}
        data-qa-select-value={value || "unselected"}
        data-qa-select-isrequired={required === true}
        data-qa-select-isdisabled={disabled === true}
        {...qa}
        {...inputProps}
      >
        {children}
      </select>

      <Arrow size={size}>
        <Icon
          name="chevron-down-outline"
          fixedWidth
          size={size === "small" ? "small" : "medium"}
          aria-hidden
        />
      </Arrow>
    </Container>
  );
};

export default Select;
