import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import type { Moment } from "moment";
import React from "react";
import Icon, { type TypeIconName } from "@sproutsocial/seeds-react-icon";
import { CalendarDay } from "./styles";
import type { TypeCommonDatePickerProps } from "./types";

type TypeCalendarNavButtonType = "left" | "right";

const iconNames: { [key in TypeCalendarNavButtonType]: TypeIconName } = {
  left: "arrow-left-solid",
  right: "arrow-right-solid",
};

export const CalendarNavButton = ({
  type,
}: {
  type: TypeCalendarNavButtonType;
}) => <Icon size="mini" name={iconNames[type]} aria-hidden />;

export const commonDatePickerProps: TypeCommonDatePickerProps = {
  hideKeyboardShortcutsPanel: true,
  daySize: 30,
  navPrev: <CalendarNavButton type="left" />,
  navNext: <CalendarNavButton type="right" />,
  renderDayContents: (day, modifiers) => (
    <CalendarDay day={day} modifiers={modifiers}>
      {day.format("D")}
    </CalendarDay>
  ),
  initialVisibleMonth: null,
};
// Testing utilities

export const formatDateAsCalendarHeader = (date: Moment): string =>
  date.format("MMMM YYYY");

export const formatDateAsCalendarDay = (date: Moment): string =>
  date.format("dddd, MMMM D, YYYY");

export const getVisibleMonthWithReactDatesInternalApi = (
  container: HTMLElement
): string =>
  container.querySelector("[data-visible=true] strong")?.textContent ?? "";

// Receives a single Moment and returns an array of Moments, one for each currently visible month.
// @ts-ignore unknown types
export const getVisibleMonths = (initialMonth, numberOfMonths) => {
  const months = [initialMonth];
  for (let i = 1; i < numberOfMonths; i++) {
    months.push(initialMonth.clone().add(i, "month"));
  }
  return months;
};

// Default setStatusText prop for both SingleDatePicker and DateRangePicker
// @ts-ignore unknown types
export const DefaultSetStatusText = (dates) =>
  dates.map(formatDateAsCalendarHeader).join(", ");
