import * as React from "react";
import { useState, useEffect } from "react";
import MOTION from "@sproutsocial/seeds-motion/unitless";
import Popout from "@sproutsocial/seeds-react-popout";
import { StyledTooltipContent } from "./styles";
import type { TypeTooltipProps, TypeTooltipContent } from "./TooltipTypes";

let idCounter = 0;

const hasAttribute = (child: React.ReactNode, attribute: string) => {
  return React.isValidElement(child) && child.props[attribute] !== undefined;
};

/** Tooltip Styled Popout wrapper for handling events */
const TooltipBubble = ({
  appearance = "pill",
  children,
  onFocus,
  onBlur,
  ...rest
}: TypeTooltipContent) => {
  // @ts-ignore Will fix during refactor
  const handleFocus = (e) => {
    onFocus(e);
  };
  // @ts-ignore Will fix during refactor
  const handleBlur = (e) => {
    onBlur(e);
  };
  return (
    <StyledTooltipContent
      role="tooltip"
      appearance={appearance}
      borderRadius={appearance === "box" ? 500 : "5000em"}
      px={400}
      py={appearance === "box" ? 400 : 200}
      m={200}
      color="text.body"
      bg="container.background.base"
      boxShadow="medium"
      border={500}
      borderColor="container.border.base"
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleFocus}
      onMouseLeave={handleBlur}
      tabIndex={0}
      {...rest}
    >
      {children}
    </StyledTooltipContent>
  );
};

/** Core component */
const Tooltip = ({
  content,
  children,
  enterDelay = MOTION.MOTION_DURATION_FAST * 1000,
  placement = "auto",
  appearance,
  zIndex = 7,
  qa,
  popoutProps,
  truncated = false,
  onFocus,
  onBlur,
  ...rest
}: TypeTooltipProps) => {
  const [shouldShow, setShouldShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [id] = useState(`Racine-tooltip-${idCounter++}`);
  const isInvalidContent = content === null || content === undefined;

  // @ts-ignore Will fix during refactor
  const show = (e) => {
    onFocus?.(e);
    setShouldShow(true);
  };
  // @ts-ignore Will fix during refactor
  const hide = (e) => {
    onBlur?.(e);
    setShouldShow(false);
  };

  const exitDelay = MOTION.MOTION_DURATION_FAST * 1000;
  const defaultAppearance =
    appearance || (typeof content === "object" ? "box" : "pill");

  /** Handles all the logic around whether to display/not display */
  useEffect(() => {
    const documentBody = document.body;
    let timeout;
    const onEsc = (e: KeyboardEvent): void => {
      // older browsers use "Esc"
      if (["Escape", "Esc"].includes(e.key)) {
        setIsOpen(false);
        setShouldShow(false);
      }
    };

    if (shouldShow) {
      timeout = setTimeout(() => setIsOpen(true), enterDelay);
    } else {
      timeout = setTimeout(() => {
        setIsOpen(false);
      }, exitDelay);
    }

    // We only want listeners from the tooltip if its open in the first place
    if (isOpen) {
      documentBody.addEventListener("keydown", onEsc, { capture: true });
    }
    return () => {
      documentBody.removeEventListener("keydown", onEsc, { capture: true });
      clearTimeout(timeout);
    };
  }, [isOpen, setShouldShow, shouldShow, enterDelay, exitDelay]);

  /** The wrapped content of whats inside the Tooltip */
  const TooltipContent = () => (
    <TooltipBubble
      appearance={defaultAppearance}
      onFocus={show}
      onBlur={hide}
      aria-expanded={isOpen}
      id={id}
      {...rest}
    >
      {content}
    </TooltipBubble>
  );

  return (
    <Popout
      content={!isInvalidContent ? TooltipContent : undefined}
      isOpen={isOpen}
      placement={placement}
      qa={{
        "data-qa-tooltip": id,
        ...qa,
      }}
      id={id + "-wrapper"}
      focusOnContent={false}
      zIndex={zIndex}
      aria-haspopup="false"
      display={truncated ? "flex" : undefined}
      disableWrapperAria={true} // required so that the child span doesnt take in redundant aria props
      {...popoutProps}
    >
      <span
        onBlur={hide}
        onFocus={show}
        onMouseEnter={show}
        onMouseLeave={hide}
        style={
          truncated
            ? {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }
            : {}
        }
      >
        {React.isValidElement(children)
          ? React.cloneElement(children as React.ReactElement, {
              //** There may be cases where the Tooltip's child needs to properly describe its role as expanding a drawer, in which case that propery takes priority */
              "aria-expanded": hasAttribute(children, "aria-expanded")
                ? children.props["aria-expanded"]
                : isOpen,
              "aria-describedby": id,
            })
          : children}
      </span>
    </Popout>
  );
};

export default Tooltip;
