import React, { useState } from "react";
import type { TypeStatefulSingleDatePickerProps } from "./SingleDatePickerTypes";
import SingleDatePicker from "./SingleDatePicker";

export const StatefulSingleDatePicker = ({
  date,
  onDateChange,
  ...rest
}: TypeStatefulSingleDatePickerProps) => {
  const [stateDate, setDate] = useState(date);

  // @ts-ignore unknown types
  const handleDateChange = (date) => {
    onDateChange && onDateChange(date);
    setDate(date);
  };

  return (
    <SingleDatePicker
      date={stateDate}
      onDateChange={handleDateChange}
      {...rest}
    />
  );
};
