import * as React from "react";
import Container, { Accessory } from "./styles";
import type { TypeTextareaProps } from "./TextareaTypes";

/**
 * @deprecated Use TypeTextareaProps from root instead
 */
export type TypeProps = TypeTextareaProps;

export default class Textarea extends React.Component<TypeTextareaProps> {
  static defaultProps = {
    rows: 4,
  };

  handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleKeyDown = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  handleKeyUp = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  };

  override render() {
    const {
      autoFocus,
      disabled,
      readOnly,
      isInvalid,
      id,
      name,
      placeholder,
      value,
      enableSpellcheck,
      enableResize,
      required,
      rows,
      elemBefore,
      elemAfter,
      maxLength,
      ariaLabel,
      ariaDescribedby,
      innerRef,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      color,
      qa = {},
      inputProps = {},
      ...rest
    } = this.props;

    return (
      <Container
        hasBeforeElement={!!elemBefore}
        hasAfterElement={!!elemAfter}
        disabled={disabled}
        invalid={isInvalid}
        resizable={enableResize}
        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={color}
        data-qa-textarea={id}
        data-qa-textarea-isdisabled={disabled === true}
        data-qa-textarea-isrequired={required === true}
        data-qa-textarea-isinvalid={isInvalid === true}
        {...qa}
        {...rest}
      >
        {elemBefore && <Accessory before>{elemBefore}</Accessory>}

        <textarea
          id={id}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedby}
          aria-invalid={isInvalid}
          value={value}
          name={name}
          placeholder={placeholder}
          rows={rows}
          disabled={disabled}
          readOnly={Boolean(readOnly)}
          autoFocus={autoFocus}
          spellCheck={enableSpellcheck}
          required={required}
          maxLength={maxLength}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          ref={innerRef}
          data-qa-textarea-input=""
          data-qa-input={name}
          {...inputProps}
        />

        {elemAfter && <Accessory after>{elemAfter}</Accessory>}
      </Container>
    );
  }
}
