import * as React from "react";
import styled from "styled-components";
import { mergeRefs } from "@sproutsocial/seeds-react-utilities";
import { useInteractiveColor } from "@sproutsocial/seeds-react-hooks";
import Button from "@sproutsocial/seeds-react-button";
import Icon from "@sproutsocial/seeds-react-icon";
import Container, { Accessory } from "./styles";
import type { TypeInputProps } from "./InputTypes";

interface TypeState {
  hasValue: boolean;
}

// Using Context so that Input's Input.ClearButton-specific props can be passed to Input.ClearButton,
// regardless of whether it is manually included as elemAfter or automatically included for type="search" Inputs.
type TypeInputContext = Partial<{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleClear: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  clearButtonLabel: string;
  hasValue: boolean;
  size: "large" | "small" | "default";
}>;

const InputContext = React.createContext<TypeInputContext>({});

const StyledButton = styled(Button)`
  &:hover,
  &:active {
    color: ${(props) => useInteractiveColor(props.theme.colors.icon.base)};
  }
`;

const ClearButton = () => {
  const {
    handleClear,
    clearButtonLabel,
    hasValue,
    size: inputSize,
  } = React.useContext(InputContext);

  // Hide the button when there is no text to clear.
  if (!hasValue) {
    return null;
  }

  // Warn if clearButtonLabel is not included, so that the unlocalized fallback will not be mistaken for a proper label.
  if (!clearButtonLabel) {
    // eslint-disable-next-line no-console
    console.warn(
      "Warning: clearButtonLabel prop is required when using Input.ClearButton. Please pass a localized clearButtonLabel to Input."
    );
  }

  // Reduce Button padding for size small Inputs so that the Button won't go outside the bounds of the Input.
  // This adjustment is handled automatically for default and large Inputs via Button's size. There is no "small" Button.
  const py = inputSize === "small" ? 100 : undefined;
  const px = inputSize === "small" ? 200 : undefined;
  const buttonSize = inputSize === "small" ? "default" : inputSize;
  return (
    <StyledButton
      onClick={handleClear}
      size={buttonSize}
      py={py}
      px={px}
      title={clearButtonLabel || "Clear"}
      ariaLabel={clearButtonLabel || "Clear"}
      color="icon.base"
    >
      <Icon name="circle-x-outline" aria-hidden />
    </StyledButton>
  );
};

ClearButton.displayName = "Input.ClearButton";

// Used for positioning elementAfter. This logic will detect if the element is a ClearButton,
// regardless of whether it was manually passed as elemAfter or automatically added to a search Input.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isClearButton = (elem: any) => {
  if (elem?.type) {
    return elem.type.displayName === "Input.ClearButton";
  }

  return false;
};

class Input extends React.Component<TypeInputProps, TypeState> {
  constructor(props: TypeInputProps) {
    super(props);
    this.state = {
      // Tracking hasValue in state allows us to hide ClearButton when there is no value to clear
      // for both controlled and uncontrolled inputs.
      hasValue: !!props.value,
    };
  }

  override componentDidUpdate({ value: prevValue }: TypeInputProps) {
    // Update hasValue state whenever props value changes
    const { value } = this.props;
    if (value !== prevValue) {
      this.updateState(value || "");
    }
  }

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    type: "text",
    size: "default",
    appearance: "primary",
  };

  static ClearButton = ClearButton;
  // Define our own ref for use in handleClear.
  // We use mergeRefs to pass both this.inputRef and this.props.innerRef to input.
  inputRef = React.createRef<HTMLInputElement>();

  handleBlur = (e: React.FocusEvent<HTMLInputElement>) =>
    this.props.onBlur?.(e);

  handleClear = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const input = this.inputRef.current;

    if (input) {
      // Clear the value via the input prototype, then dispatch an input event in order to trigger handleChange
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      )?.set;

      nativeInputValueSetter?.call(input, "");
      const inputEvent = new Event("input", {
        bubbles: true,
      });
      input.dispatchEvent(inputEvent);

      // call any onClear callback
      this.props.onClear?.(e);

      // wait for next cycle to update focus
      setTimeout(() => {
        input.focus();
      }, 0);
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange?.(e, e.currentTarget.value);
    // needed for uncontrolled inputs
    this.updateState(e.currentTarget.value);
  };

  handleFocus = (e: React.FocusEvent<HTMLInputElement>) =>
    this.props.onFocus?.(e);

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
    this.props.onKeyDown?.(e, e.currentTarget.value);

  handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) =>
    this.props.onKeyUp?.(e, e.currentTarget.value);

  handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) =>
    this.props.onPaste?.(e, e.currentTarget.value);

  updateState = (inputValue: string) => {
    const hasValue = inputValue !== "";
    const previousHasValue = this.state.hasValue;

    // Only update state if the value of `hasValue` has changed to avoid unnecessary renders.
    if (hasValue !== previousHasValue) {
      this.setState({
        hasValue,
      });
    }
  };

  override render() {
    const {
      autoComplete,
      autoFocus,
      disabled,
      readOnly,
      isInvalid,
      hasWarning,
      id,
      name,
      placeholder,
      type,
      required,
      value,
      elemBefore,
      elemAfter,
      maxLength,
      ariaLabel,
      ariaDescribedby,
      clearButtonLabel,
      innerRef = null,
      // These functions are used in the class functions above, but need to be extracted in order for `rest` to be correct
      /* eslint-disable @typescript-eslint/no-unused-vars */
      onBlur,
      onChange,
      onClear,
      onFocus,
      onKeyDown,
      onKeyUp,
      onPaste,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      inputProps = {},
      qa = {},
      appearance,
      size,
      ...rest
    } = this.props;

    // Convert autoComplete from a boolean prop to a string value.
    let autoCompleteValue: "on" | "off" | undefined;

    if (autoComplete !== undefined) {
      autoCompleteValue = autoComplete ? "on" : "off";
    }

    // Add default elemBefore and elemAfter elements if type is search.
    const elementBefore =
      type === "search" && !elemBefore ? (
        <Icon name="magnifying-glass-outline" aria-hidden color="icon.base" />
      ) : (
        elemBefore
      );

    // Do not add a ClearButton if an elemAfter prop was passed.
    const elementAfter =
      type === "search" && !elemAfter ? <ClearButton /> : elemAfter;

    return (
      <Container
        hasBeforeElement={!!elementBefore}
        hasAfterElement={!!elementAfter}
        disabled={disabled}
        invalid={!!isInvalid}
        warning={hasWarning}
        appearance={appearance}
        size={size}
        {...rest}
      >
        <InputContext.Provider
          value={{
            handleClear: this.handleClear,
            hasValue: this.state.hasValue,
            clearButtonLabel,
            size,
          }}
        >
          {elementBefore && <Accessory before>{elementBefore}</Accessory>}

          <input
            aria-invalid={!!isInvalid}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedby}
            autoComplete={autoCompleteValue}
            autoFocus={autoFocus}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            required={required}
            value={value}
            maxLength={maxLength}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            onPaste={this.handlePaste}
            ref={mergeRefs<HTMLInputElement>(innerRef, this.inputRef)}
            data-qa-input={name || ""}
            data-qa-input-isdisabled={disabled === true}
            data-qa-input-isrequired={required === true}
            {...qa}
            {...inputProps}
          />

          {elementAfter && (
            <Accessory after isClearButton={isClearButton(elementAfter)}>
              {elementAfter}
            </Accessory>
          )}
        </InputContext.Provider>
      </Container>
    );
  }
}

export default Input;
