import * as React from "react";
import { useContext } from "react";
import Box from "@sproutsocial/seeds-react-box";
import Button from "@sproutsocial/seeds-react-button";
import Icon from "@sproutsocial/seeds-react-icon";
import Text from "@sproutsocial/seeds-react-text";
import { Container, Content, Header, Footer, Body } from "./styles";
import type {
  TypeModalProps,
  TypeModalCloseButtonProps,
  TypeModalContentProps,
  TypeModalFooterProps,
  TypeModalHeaderProps,
} from "./ModalTypes";

type TypeModalContext = Partial<{
  onClose: () => void;
  closeButtonLabel: string;
  label: string;
}>;

const ModalContext = React.createContext<TypeModalContext>({});

const ModalHeader = (props: TypeModalHeaderProps) => {
  const { title, subtitle, children, bordered, ...rest } = props;
  return (
    <Header bordered={title || subtitle || bordered} {...rest}>
      {children ? (
        children
      ) : (
        <React.Fragment>
          <Box>
            {title && (
              <Text as="h1" fontSize={400} fontWeight="semibold">
                {title}
              </Text>
            )}
            {subtitle && (
              <Text as="div" fontSize={200}>
                {subtitle}
              </Text>
            )}
          </Box>
          <Box display="flex" alignItems="center" justify-content="flex-end">
            <ModalCloseButton ml={400} />
          </Box>
        </React.Fragment>
      )}
    </Header>
  );
};

const ModalCloseButton = (props: TypeModalCloseButtonProps) => {
  const { onClose, closeButtonLabel } = useContext(ModalContext);
  if (!onClose) return null;
  return (
    <Button onClick={onClose} {...props}>
      <Icon name="x-outline" ariaLabel={closeButtonLabel} />
    </Button>
  );
};

const ModalFooter = (props: TypeModalFooterProps) => (
  <Footer borderTop={500} borderColor="container.border.base" {...props} />
);

ModalFooter.defaultProps = {
  bg: "container.background.base",
};

const ModalContent = React.forwardRef(
  ({ children, ...rest }: TypeModalContentProps, ref) => {
    const { label } = useContext(ModalContext);
    return (
      <Content data-qa-modal data-qa-label={label} ref={ref} {...rest}>
        {children}
      </Content>
    );
  }
);

/**
 * The modal you want
 */
const Modal = (props: TypeModalProps) => {
  const {
    appElementSelector,
    children,
    isOpen,
    label,
    onClose,
    closeButtonLabel,
    width,
    zIndex,
    data = {},
    ...rest
  } = props;

  const isCloseable = Boolean(onClose);
  const appElement =
    appElementSelector && document
      ? (document.querySelector(appElementSelector) as HTMLElement)
      : undefined;

  return (
    <Container
      appElement={appElement}
      ariaHideApp={!!appElement}
      isOpen={isOpen}
      contentLabel={label}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onRequestClose={onClose || (() => {})}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={isCloseable}
      shouldCloseOnEsc={isCloseable}
      shouldReturnFocusAfterClose={true}
      closeTimeoutMS={200}
      role="dialog"
      width={width}
      zIndex={zIndex}
      data={{
        "qa-modal": "",
        "qa-modal-isopen": isOpen,
        ...data,
      }}
      {...rest}
    >
      <React.Fragment>
        <Body />

        <ModalContext.Provider
          value={{
            onClose,
            closeButtonLabel,
            label,
          }}
        >
          {children}
        </ModalContext.Provider>
      </React.Fragment>
    </Container>
  );
};

Modal.defaultProps = {
  width: "800px",
  zIndex: 6,
};

ModalHeader.displayName = "Modal.Header";
ModalFooter.displayName = "Modal.Footer";
ModalContent.displayName = "Modal.Content";
ModalCloseButton.displayName = "Modal.CloseButton";

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Content = ModalContent;
Modal.CloseButton = ModalCloseButton;

export default Modal;
