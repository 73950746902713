import styled from "styled-components";
import Box from "@sproutsocial/seeds-react-box";
import type { SkeletonProps } from "./SkeletonTypes";

/**
 *
 * @param borderRadius
 * @returns classname string for handling appropriate loader style depending on passed in properties
 */
// @ts-ignore TODO: fix noImplicitAny error here. IDK why there are no types for this component
const SkeletonAttrs = ({ borderRadius, height, width }) => ({
  className:
    borderRadius === "pill" && height === width ? "circular" : "linear",
});

const Skeleton = styled(Box).attrs(SkeletonAttrs)`
  position: relative;
  background: ${(props) =>
    props.theme.colors.container.background.decorative.neutral};
  overflow: hidden;
  &.circular {
    &:before {
      position: absolute;
      top: -25%;
      left: -25%;
      content: '';
      background-image: ${(props) => `conic-gradient(
      ${props.theme.colors.container.background.decorative.neutral} 270deg,
      ${props.theme.colors.container.border.decorative.neutral} 300deg
    );`};
      height: 150%;
      width: 150%;
      animation: SkeletonRotate 2s infinite linear;
    }
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      background: ${(p) => p.theme.colors.app.background.base};
      border-radius: 50%;
    }
  }
  &.linear {
    position: relative;
    background-image: ${(props) => `linear-gradient(
      288deg,
      ${props.theme.colors.container.background.decorative.neutral} 32%,
      ${props.theme.colors.container.border.decorative.neutral},
      ${props.theme.colors.container.background.decorative.neutral} 68%
    );`}
    background-size: 400%;
    background-repeat: no-repeat;
    animation: SkeletonShimmer 2s linear infinite reverse;
    overflow: hidden;
    &:after {
      position: absolute;
      bottom: 0;
      content: "";
      height: calc(100% - 4px);
      width: 100%;
      background: ${(props) =>
        props.theme.colors.container.background.decorative.neutral};
      
    }
  }

  @media (prefers-reduced-motion) {
    &.linear,
    &.circular::before {
      animation: none;
    }
    &:before,
    &:after {
      display: none;
    }
    &.linear,
    &.circular {
      border: 1px solid ${(props) =>
        props.theme.colors.container.border.decorative.neutral};
      animation: SkeletonPulse 2s linear infinite alternate; 
    }
  }

  @keyframes SkeletonRotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes SkeletonRotateFade {
    50% {
      transform: rotate(360deg);
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: rotate(720deg);
      opacity: 0;
    }
  }

  @keyframes SkeletonShimmer {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  @keyframes SkeletonPulse {
    0% {
      border-color: ${(props) =>
        props.theme.colors.container.border.decorative.neutral}FF;
    }
    100% {
      border-color: ${(props) =>
        props.theme.colors.container.border.decorative.neutral}1A;
    }
  }
`;

// @TODO: Refine types that are passed to SkeletonComponent
export const SkeletonComponent = ({
  borderRadius,
  height,
  width,
  ...props
}: SkeletonProps) => {
  return (
    <Skeleton
      borderRadius={borderRadius}
      height={height}
      width={width}
      {...props}
    />
  );
};

export default Skeleton;
