import * as React from "react";
import Icon from "@sproutsocial/seeds-react-icon";
import TableCell from "../TableCell";
import Container, { Detail, Trigger } from "./styles";
import type { TypeTableRowAccordionProps } from "./TableRowAccordionTypes";

/**
 * The table row accordion component allows for rendering a row of tabular data along with a addtional content to be rendered inside of an accordion drawer. This component is meant to be used with the table components rowRender mentod.
 */
export default class TableRowAccordion extends React.Component<TypeTableRowAccordionProps> {
  handleToggle: React.MouseEventHandler<HTMLTableRowElement> = (e) => {
    const { onToggle, id } = this.props;
    e.stopPropagation();

    if (onToggle) {
      onToggle(id);
    }
  };

  override render() {
    const {
      id,
      cells,
      detail,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onToggle,
      isExpanded,
      color,
      ...rest
    } = this.props;

    return (
      <Container
        {...rest}
        data-qa-table-row-accordion={isExpanded}
        key={id}
        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={color}
      >
        <tr data-tablerowaccordion-summary onClick={this.handleToggle}>
          {cells.map((td) => {
            return <TableCell {...td} key={td.id} />;
          })}
          <TableCell
            id="tableRowAccordion_trigger"
            content={
              // TODO: This trigger needs an accessible label passed in via props
              <Trigger
                data-tablerowaccordion-trigger
                onClick={this.handleToggle}
                role="button"
              >
                <Icon
                  name={
                    isExpanded ? "chevron-up-outline" : "chevron-down-outline"
                  }
                  aria-hidden
                />
              </Trigger>
            }
          />
        </tr>
        <Detail isExpanded={isExpanded} data-tablerowaccordion-detail>
          <TableCell
            id="tableRowAccordion_detail"
            colSpan={100}
            content={detail}
          />
        </Detail>
      </Container>
    );
  }
}
