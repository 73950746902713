import * as React from "react";
import Container, { Input, InputWrapper, LabelText } from "./styles";
import type { TypeRadioProps } from "./RadioTypes";

/**
 * Primitive Radio Element
 */
export default class Radio extends React.Component<TypeRadioProps> {
  static defaultProps = {
    disabled: false,
  };

  handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (!!this.props.checked || this.props.disabled) {
      return;
    }

    this.props.onChange && this.props.onChange(e);
  };

  handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  override render() {
    const {
      id,
      value,
      name,
      label,
      checked,
      disabled,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      onChange,
      onFocus,
      onBlur,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      ariaLabel,
      qa = {},
      color,
      tabIndex,
      ...rest
    } = this.props;
    return (
      <Container
        as={label && "label"}
        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={color}
        {...rest}
      >
        <InputWrapper checked={checked} disabled={disabled}>
          <Input
            type="radio"
            id={id}
            tabIndex={tabIndex}
            aria-label={ariaLabel}
            value={value}
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            data-qa-radio={name || ""}
            data-qa-radio-ischecked={checked === true}
            data-qa-radio-isdisabled={disabled === true}
            {...qa}
          />
        </InputWrapper>
        {label && <LabelText disabled={disabled}>{label}</LabelText>}
      </Container>
    );
  }
}
