import React, { useState } from "react";
// @ts-ignore unknown types
import { START_DATE } from "react-dates/constants";
import type { TypeStatefulDateRangePickerProps } from "./DateRangePickerTypes";
import DateRangePicker from "./DateRangePicker";

export const StatefulDateRangePicker = ({
  startDate,
  endDate,
  onDatesChange,
  onFocusChange,
  ...rest
}: TypeStatefulDateRangePickerProps) => {
  const [dates, setDate] = useState({
    startDate,
    endDate,
  });
  const [focusedInput, setFocusedInput] = React.useState(START_DATE);

  // @ts-ignore unknown types
  const handleDatesChange = (nextDates) => {
    onDatesChange && onDatesChange(nextDates);
    setDate(nextDates);
  };

  // @ts-ignore unknown types
  const handleFocusChange = (nextFocusedInput) => {
    onFocusChange && onFocusChange(nextFocusedInput);
    setFocusedInput(
      // null means that we've selected an end date. we want to go back to START_DATE
      // so the user can modify their selection. if focusedInput === null then it won't
      // respond to click or keyboard events
      nextFocusedInput === null ? START_DATE : nextFocusedInput
    );
  };

  return (
    <DateRangePicker
      startDate={dates.startDate}
      endDate={dates.endDate}
      focusedInput={focusedInput}
      onDatesChange={handleDatesChange}
      onFocusChange={handleFocusChange}
      {...rest}
    />
  );
};
