import * as React from "react";
import { Children } from "react";
import { normalizeResponsiveProp } from "./utils";
import Box from "@sproutsocial/seeds-react-box";
import type { TypeStackProps } from "./StackTypes";

const stackStyles = {
  horizontal: {
    left: {
      alignItems: "center",
    },
    center: {
      alignItems: "center",
      justifyContent: "center",
    },
    right: {
      alignItems: "center",
      justifyContent: "flex-end",
    },
    stretch: {
      alignItems: "center",
    },
  },
  vertical: {
    left: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    center: {
      flexDirection: "column",
      alignItems: "center",
    },
    right: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
    stretch: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
};

const Stack = ({
  children,
  space = 300,
  align = "left",
  direction = "vertical",
  ...rest
}: TypeStackProps) => {
  const stackItems = Children.toArray(children);
  const responsiveAlignment = normalizeResponsiveProp(align);
  const responsiveDirection = normalizeResponsiveProp(direction);
  const responsiveSpace = normalizeResponsiveProp(space);
  const initialValue = ["initial", "initial", "initial", "initial", "initial"];

  // TODO: rely on styled system to solve this during the refactor
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styleProps: any = {
    alignItems: [...initialValue],
    flexDirection: [...initialValue],
    justifyContent: [...initialValue],
  };
  const childFlexBasis: (string | null)[] = [...initialValue];
  const childMarginProps = {
    marginLeft: [...initialValue],
    marginTop: [...initialValue],
  };

  initialValue.forEach((_, i) => {
    const currentDirection = responsiveDirection[i] as keyof typeof stackStyles;
    const currentAlignment = responsiveAlignment[
      i
    ] as keyof (typeof stackStyles)["horizontal"];
    const currentSpace = responsiveSpace[i];

    if (currentDirection && currentAlignment) {
      const styles: { [key: string]: string } =
        stackStyles[currentDirection][currentAlignment];
      Object.keys(styleProps).forEach((key: string) => {
        styleProps[key][i] = styles[key] || "initial";
      });
    }

    const flexBasis =
      currentAlignment === "stretch" && currentDirection === "horizontal"
        ? "100%"
        : null;
    childFlexBasis[i] = flexBasis;

    const margin =
      currentDirection === "horizontal" ? "marginLeft" : "marginTop";

    const opposite =
      currentDirection === "horizontal" ? "marginTop" : "marginLeft";
    childMarginProps[margin][i] = currentSpace;
    // styled system can pass numbers that map to theme values and TS doesn't like it
    childMarginProps[opposite][i] = 0 as unknown as string;
  });

  return (
    <Box display="flex" {...styleProps} {...rest}>
      {stackItems.map((item, i) => (
        <Box
          flexBasis={childFlexBasis}
          {...(i !== 0 ? childMarginProps : {})}
          key={i}
        >
          {item}
        </Box>
      ))}
    </Box>
  );
};

export default Stack;
