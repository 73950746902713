import React, { useCallback, useState } from "react";
import moment from "moment";
import DayPickerSingleDateController from "react-dates/lib/components/DayPickerSingleDateController";
import { ReactDatesCssOverrides } from "../styles";
import {
  commonDatePickerProps,
  DefaultSetStatusText,
  getVisibleMonths,
} from "../common";
import type { TypeSingleDatePickerProps } from "./SingleDatePickerTypes";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";

const noop = () => {};

const SingleDatePicker = ({
  onDateChange,
  setStatusText = DefaultSetStatusText,
  date = null,
  focused = false,
  onFocusChange = noop,
  initialVisibleMonth,
  numberOfMonths = 1,
  onBlur,
  ...rest
}: TypeSingleDatePickerProps) => {
  const [statusText, updateStatusText] = useState(() =>
    setStatusText(
      getVisibleMonths(
        moment(initialVisibleMonth?.() || date || undefined),
        numberOfMonths
      )
    )
  );

  const handleMonthClick = useCallback(
    // @ts-ignore unknown types
    (date) => {
      updateStatusText(setStatusText(getVisibleMonths(date, numberOfMonths)));
    },
    [numberOfMonths, setStatusText]
  );
  const wrappedOnBlur = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      onFocusChange?.({ focused: false });
      onBlur?.(event);
    },
    [onBlur, onFocusChange]
  );

  return (
    <>
      <ReactDatesCssOverrides />
      <VisuallyHidden>
        <div role="status">{statusText}</div>
      </VisuallyHidden>
      <DayPickerSingleDateController
        {...commonDatePickerProps}
        date={date}
        numberOfMonths={numberOfMonths}
        onDateChange={onDateChange}
        initialVisibleMonth={initialVisibleMonth || null}
        focused={focused}
        onBlur={wrappedOnBlur}
        onFocusChange={onFocusChange}
        onPrevMonthClick={handleMonthClick}
        onNextMonthClick={handleMonthClick}
        {...rest}
      />
    </>
  );
};

export default React.memo<TypeSingleDatePickerProps>(SingleDatePicker);
