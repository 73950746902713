import * as React from "react";
import { useState, useContext } from "react";
import Box from "@sproutsocial/seeds-react-box";
import {
  SegmentedControlContainer,
  SegmentedControlItemContainer,
  SegmentedControlLabel,
} from "./styles";
import type {
  TypeSegmentedControlProps,
  TypeSegmentedControlItemProps,
} from "./SegmentedControlTypes";

let nameCounter = 0;
let idCounter = 0;

interface TypeSegmentedControlContext {
  name: string;
  selectedValue: string;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const SegmentedControlContext = React.createContext<
  TypeSegmentedControlContext | null | undefined
>(null);

const SegmentedControlItem = ({
  value,
  children,
  disabled,
  ...rest
}: TypeSegmentedControlItemProps) => {
  const context = useContext(SegmentedControlContext);

  if (!context) {
    return null;
  }

  const { name, selectedValue, onChange } = context;

  /* eslint-disable-next-line */
  const [id] = useState(`${name}-${idCounter++}`);
  const isChecked = value === selectedValue;

  return (
    <SegmentedControlItemContainer
      data-segmentedcontrol-isactive={isChecked}
      data-qa-segmentedcontrol-item={value}
      data-qa-segmentedcontrol-ischecked={isChecked === true}
      disabled={disabled}
      {...rest}
    >
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <SegmentedControlLabel as="label" htmlFor={id} isActive={isChecked}>
        {children}
      </SegmentedControlLabel>
    </SegmentedControlItemContainer>
  );
};

const SegmentedControl = ({
  selectedValue,
  label,
  onChange,
  children,
  ...rest
}: TypeSegmentedControlProps) => {
  const [name] = useState(`Racine-segmented-control-${nameCounter++}`);
  return (
    <SegmentedControlContext.Provider
      value={{
        name,
        selectedValue,
        onChange,
      }}
    >
      <SegmentedControlContainer
        forwardedAs="fieldset"
        data-qa-segmentedcontrol={label}
        data-qa-segmentedcontrol-value={selectedValue}
        {...rest}
      >
        <legend>{label}</legend>

        <Box display="flex">{children}</Box>
      </SegmentedControlContainer>
    </SegmentedControlContext.Provider>
  );
};

SegmentedControlItem.displayName = "SegmentedControl.Item";
SegmentedControl.Item = SegmentedControlItem;

export default SegmentedControl;
