import type { ElementRef } from "react";

type TypeRef<E> =
  | {
      current: null | E;
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((arg0: ElementRef<any> | E) => void)
  | React.Ref<E>;

// Allows a component to pass its own ref *and* a ref prop to the same element.
// Via https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
export function mergeRefs<E extends HTMLElement>(
  ...refs: (TypeRef<E>[] | TypeRef<E> | null)[]
): TypeRef<E> | null {
  // Calling flat makes it possible to pass refs as an array or as individual arguments
  const filteredRefs = refs.flat().filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0] as TypeRef<E>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        if ("current" in ref && ref.current !== undefined) {
          (ref as { current: any }).current = inst;
        }
      }
    }
  };
}
