import { COLOR_BLUE_900 } from "@sproutsocial/seeds-color";

const defaultLink = {
  color: "link",
  hoverColor: "link.hover",
  activeColor: COLOR_BLUE_900,
  fontWeight: "semibold",
};

const linkTheme = {
  default: defaultLink,
};

export default linkTheme;
