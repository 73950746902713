import * as React from "react";
// @ts-expect-error lru-memoize is not typed
import memoize from "lru-memoize";
import { EM_DASH } from "./constants";
import Tooltip from "@sproutsocial/seeds-react-tooltip";
import type { TypeTextProps } from "@sproutsocial/seeds-react-text";
import { VisuallyHidden } from "@sproutsocial/seeds-react-visually-hidden";

import {
  DEFAULT_THRESHOLD,
  MEMO_CACHE_SIZE,
  COMPARE_OBJECTS,
  MAX_PRECISION,
  ABBREV_PRECISION,
  DefaultPrecisions,
} from "./constants";
import { AbbrContainer, Container } from "./styles";
import type { EnumNumeralFormat, TypeNumeralProps } from "./NumeralTypes";

interface TypeFormatOptions {
  locale: string;
  format: EnumNumeralFormat;
  currency: string;
  min: number;
  max: number;
}

interface TypeFormatters {
  standard: Intl.NumberFormat;
  abbreviated: Intl.NumberFormat;
}

interface TypeArgs {
  value: number;
  canAbbreviate: boolean;
  invalidNumberLabel?: string;
  ariaLabel?: string;
  options: TypeFormatOptions;
  qa: object | null | undefined;
  rest: Omit<TypeTextProps, "children">;
}

const _getNumberFormatters = (options: TypeFormatOptions): TypeFormatters => {
  const { locale, format, currency, min, max } = options;
  const compactPrecision = min === max ? min : ABBREV_PRECISION;

  const _currency = format === "currency" ? currency : undefined;

  const standard: Intl.NumberFormatOptions = {
    style: format,
    minimumFractionDigits: min,
    maximumFractionDigits: max,
    currency: _currency,
  };
  const compact: Intl.NumberFormatOptions = {
    style: format,
    minimumFractionDigits: compactPrecision,
    maximumFractionDigits: compactPrecision,
    currency: _currency,
    notation: "compact",
  };
  // Safari 14.1 is currently throwing errors when trying to use the compact
  // options of NumberFormat
  // https://community.atlassian.com/t5/Trello-questions/Trello-stuck-at-loading-after-Safari-14-1-update-on-macOS-Mojave/qaq-p/1675577#M45687
  let abbreviated;

  try {
    abbreviated = new Intl.NumberFormat(locale, compact);
  } catch (error) {
    abbreviated = new Intl.NumberFormat(locale, standard);
  }

  return {
    standard: new Intl.NumberFormat(locale, standard),
    abbreviated,
  };
};

// Memoize to reduce the energy of creating new instances of Intl.NumberFormat
const memoizer = memoize(MEMO_CACHE_SIZE, COMPARE_OBJECTS);
const getNumberFormatters = memoizer(_getNumberFormatters);

const getThreshold = (abbreviate: boolean | number): number => {
  if (typeof abbreviate === "number")
    return Math.max(1000, Math.abs(abbreviate));
  if (abbreviate) return DEFAULT_THRESHOLD;
  return Infinity;
};

const getMinMaxPrecision = (
  precision: TypeNumeralProps["precision"],
  format: EnumNumeralFormat
): [number, number] => {
  if (typeof precision === "number") return [precision, precision];
  if (precision === "none") return [0, MAX_PRECISION];
  return DefaultPrecisions[format];
};

const isValidNumber = (value: unknown): boolean => {
  return typeof value === "number" && isFinite(value);
};

const normalizeArgs = (props: TypeNumeralProps): TypeArgs => {
  const {
    number,
    locale = "us-EN",
    format = props.currency ? "currency" : "decimal",
    currency = "USD",
    abbreviate = true,
    invalidNumberLabel,
    precision,
    qa,
    ...rest
  } = props;
  const threshold = getThreshold(abbreviate);
  const [min, max] = getMinMaxPrecision(precision, format);

  const _number = number || 0;

  const value = _number * (format === "percent" ? 0.01 : 1);
  const canAbbreviate = Math.abs(_number) >= threshold;
  const options = {
    locale,
    format,
    currency,
    min,
    max,
  };

  return {
    value,
    canAbbreviate,
    invalidNumberLabel,
    options,
    qa,
    rest,
  };
};

const getNumeral = ({
  returnType,
  props,
}: {
  returnType: "string" | "component";
  props: TypeNumeralProps;
}): string | React.ReactNode => {
  const isReturnTypeString = returnType === "string";
  const { value, canAbbreviate, invalidNumberLabel, options, qa, rest } =
    normalizeArgs(props);

  if (!isValidNumber(props.number)) {
    return isReturnTypeString ? (
      EM_DASH
    ) : (
      <>
        {invalidNumberLabel && (
          // Give screen readers something useful to read off + hide the em dash
          <VisuallyHidden>{invalidNumberLabel}</VisuallyHidden>
        )}
        <Container aria-hidden {...qa}>
          {EM_DASH}
        </Container>
      </>
    );
  }

  const formatters = getNumberFormatters(options);
  const fullText = formatters.standard.format(value);

  if (canAbbreviate) {
    const abbreviatedText = formatters.abbreviated.format(value);

    // The following are used to debug the skipped tests which are misbehaving!!!
    // console.log({ fullText, abbreviatedText });
    // console.log({ abbreviated: formatters.abbreviated.resolvedOptions() });
    // The following check is necessary because each locale may have differing thresholds
    // for which abbreviation begins.
    if (abbreviatedText !== fullText) {
      return isReturnTypeString ? (
        abbreviatedText
      ) : (
        <Tooltip content={fullText}>
          <AbbrContainer {...qa} {...rest}>
            {abbreviatedText}
          </AbbrContainer>
        </Tooltip>
      );
    }
  }

  return isReturnTypeString ? (
    fullText
  ) : (
    <Container {...qa} {...rest}>
      {fullText}
    </Container>
  );
};

export const formatNumeral = (props: TypeNumeralProps): string => {
  return getNumeral({ returnType: "string", props }) as string;
};

const Numeral = (props: TypeNumeralProps) => {
  return getNumeral({ returnType: "component", props });
};

export default Numeral;
