import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
import { useInteractiveColor } from "@sproutsocial/seeds-react-hooks";
import type { TypeTokenProps } from "./TokenTypes";

const Container = styled.button<TypeTokenProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;
  line-height: 1;
  outline: none;
  /* Theme Properties */
  ${({ theme }) => css`
    ${theme.typography[200]}
    font-family: ${theme.fontFamily};
    font-weight: ${theme.fontWeights.normal};
    border: 1px solid ${theme.colors.container.border.base};
    border-radius: ${theme.radii[500]};
    color: ${theme.colors.text.body};
    background: ${theme.colors.container.background.base};
    padding: ${theme.space[200]} ${theme.space[300]};
    transition: all ${theme.duration.fast} ${theme.easing.ease_inout};
  `}

  &:focus {
    ${focusRing}
  }

  ${({ closeable, theme }) =>
    closeable &&
    css`
      cursor: pointer;
      &:hover,
      &:active {
        box-shadow: ${theme.shadows.low};
        border: 1px solid
          ${useInteractiveColor(theme.colors.container.border.base)};
      }
    `}

  ${({ theme, palette }) =>
    palette === "blue" &&
    css`
      color: ${theme.colors.text.body};
      background: ${theme.colors.container.background.decorative.blue};
      border: 1px solid ${theme.colors.container.border.decorative.blue};
      &:hover,
      &:active {
        cursor: pointer;
        box-shadow: ${theme.shadows.low};
        border: 1px solid
          ${useInteractiveColor(theme.colors.container.border.decorative.blue)};
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      &:hover,
      &:active {
        box-shadow: none;
        border: 1px solid ${theme.colors.container.border.base};
      }
    `}
    
  ${({ valid, theme }) =>
    !valid &&
    css`
      color: ${theme.colors.text.error};
      background: ${theme.colors.container.background.error};
      border: 1px solid ${theme.colors.container.border.error};
      &:hover {
        box-shadow: ${theme.shadows.low};
        border: 1px solid
          ${useInteractiveColor(theme.colors.container.border.error)};
      }
    `}

  ${COMMON}

    ${({ hasWarning, theme }) =>
    hasWarning &&
    css`
      color: ${theme.colors.text.body};
      background: ${theme.colors.container.background.warning};
      border: 1px solid ${theme.colors.container.border.warning};
      &:hover {
        box-shadow: ${theme.shadows.low};
        border: 1px solid
          ${useInteractiveColor(theme.colors.container.border.warning)};
      }
    `}
`;

export default Container;
