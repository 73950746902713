import * as React from "react";
import Avatar, { type TypeAvatarProps } from "@sproutsocial/seeds-react-avatar";
import Button, { type TypeButtonProps } from "@sproutsocial/seeds-react-button";
import Checkbox, {
  type TypeCheckboxProps,
} from "@sproutsocial/seeds-react-checkbox";
import Container, {
  MessageBody as StyledMessageBody,
  MessageFooter as StyledMessageFooter,
  MessageHeader as StyledMessageHeader,
  MessageMeta as StyledMessageMeta,
} from "./styles";
import { MESSAGE_DENSITIES } from "./constants";
import type { EnumDensities, TypeMessageProps } from "./MessageTypes";

const avatarSizeMap = (density: EnumDensities) => {
  switch (density) {
    case MESSAGE_DENSITIES.LARGE:
      return "40px";

    case MESSAGE_DENSITIES.COMPACT:
      return "20px";

    case MESSAGE_DENSITIES.CONDENSED:
      return "24px";

    case MESSAGE_DENSITIES.SMALL:
    default:
      return "32px";
  }
};

const MessageContext = React.createContext<{
  density: EnumDensities;
  borderColor: TypeMessageProps["borderColor"];
  bg?: TypeMessageProps["bg"];
  indentContent?: boolean;
}>({
  density: MESSAGE_DENSITIES.SMALL,
  borderColor: "container.border.base",
  bg: "container.background.base",
  indentContent: true,
});

const Message = ({
  children,
  density = MESSAGE_DENSITIES.SMALL,
  borderColor = "container.border.base",
  bg = "container.background.base",
  indentContent = true,
  innerRef,
  qa,
  ...rest
}: TypeMessageProps) => {
  return (
    <MessageContext.Provider
      value={{
        density,
        borderColor,
        indentContent,
      }}
    >
      <Container
        borderColor={borderColor}
        bg={bg}
        ref={innerRef}
        tabIndex={0}
        {...qa}
        {...rest}
      >
        {children}
      </Container>
    </MessageContext.Provider>
  );
};

const MessageBody = (props: TypeMessageProps) => (
  <MessageContext.Consumer>
    {({ density, borderColor, indentContent }) => (
      <StyledMessageBody
        data-qa-message-body
        density={density}
        borderColor={borderColor}
        indentContent={indentContent}
        {...props}
      />
    )}
  </MessageContext.Consumer>
);

MessageBody.displayName = "Message.Body";
Message.Body = MessageBody;

const MessageHeader = (props: TypeMessageProps) => (
  <MessageContext.Consumer>
    {({ density, borderColor }) => (
      <StyledMessageHeader
        density={density}
        borderColor={borderColor}
        {...props}
      />
    )}
  </MessageContext.Consumer>
);

MessageHeader.displayName = "Message.Header";
Message.Header = MessageHeader;

const MessageFooter = (props: TypeMessageProps) => (
  <MessageContext.Consumer>
    {({ density, borderColor, indentContent }) => (
      <StyledMessageFooter
        density={density}
        borderColor={borderColor}
        indentContent={indentContent}
        {...props}
      />
    )}
  </MessageContext.Consumer>
);

MessageFooter.displayName = "Message.Footer";
Message.Footer = MessageFooter;

const MessageMeta = (props: TypeMessageProps) => (
  <MessageContext.Consumer>
    {({ density, borderColor, indentContent }) => (
      <StyledMessageMeta
        density={density}
        borderColor={borderColor}
        indentContent={indentContent}
        {...props}
      />
    )}
  </MessageContext.Consumer>
);

MessageMeta.displayName = "Message.Meta";
Message.Meta = MessageMeta;

const MessageButton = (props: TypeButtonProps) => (
  <Button {...props} appearance={props.appearance || "pill"} />
);

MessageButton.displayName = "Message.Button";
Message.Button = MessageButton;

const MessageAvatar = (props: TypeAvatarProps) => (
  <MessageContext.Consumer>
    {({ density }) => <Avatar size={avatarSizeMap(density)} {...props} />}
  </MessageContext.Consumer>
);

MessageAvatar.displayName = "Message.Avatar";
Message.Avatar = MessageAvatar;

const MessageCheckbox = (props: TypeCheckboxProps) => (
  <MessageContext.Consumer>
    {({ density }) => (
      <Checkbox
        appearance="pill"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - `density` is not a valid prop for `Checkbox`
        density={density} // @ts-notes - not sure what's intended here so address during refactor
        {...props}
      />
    )}
  </MessageContext.Consumer>
);

MessageCheckbox.displayName = "Message.Checkbox";
Message.Checkbox = MessageCheckbox;

Message.Context = MessageContext;
Message.DENSITIES = MESSAGE_DENSITIES;

export default Message;
