import * as React from "react";
import Icon, { type TypeIconName } from "@sproutsocial/seeds-react-icon";
import Container, { SortIcon, UnstyledButton } from "./styles";
import { SORT_DIRECTIONS } from "./constants";
import type { TypeTableHeaderCellProps } from "./TableHeaderCellTypes";

export class TableHeaderCell extends React.Component<TypeTableHeaderCellProps> {
  static displayName: string;

  getSortIcon = (
    isSorted: boolean,
    ariaSortDirection: "ascending" | "descending" | undefined
  ) => {
    const { sortDirection } = this.props;
    let iconName: TypeIconName = "caret-up-down-outline";

    if (isSorted && sortDirection === SORT_DIRECTIONS.ASC) {
      iconName = "caret-up-solid";
    } else if (isSorted && sortDirection === SORT_DIRECTIONS.DESC) {
      iconName = "caret-down-solid";
    }

    return (
      <SortIcon>
        <Icon size="mini" name={iconName} aria-label={ariaSortDirection} />
      </SortIcon>
    );
  };

  // @ts-note: If this is set to an HTMLButtonElement event, then the `Container` freaks out
  handleClick = (
    e: React.MouseEvent<HTMLTableCellElement | HTMLButtonElement>
  ) => {
    const { onClick, onSort, isSortable, id } = this.props;

    if (onClick || isSortable) {
      e.preventDefault();
    }

    if (onClick) {
      // @ts-note: Right now the `onClick` is incorrectly set to consume an HTMLButtonElement event
      onClick(e as unknown as React.MouseEvent<HTMLButtonElement>);
      return;
    }

    if (!isSortable || !onSort) return;
    onSort(id);
  };

  override render() {
    const {
      id,
      content,
      colSpan,
      width,
      align,
      isSortable,
      sortId,
      sortDirection,
      children,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      shouldTruncate,
      onSort,
      onClick,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      color,
      ...rest
    } = this.props;

    const isSorted = sortId === id;
    const ariaSort = isSorted
      ? sortDirection === SORT_DIRECTIONS.ASC
        ? "ascending"
        : "descending"
      : undefined;

    const buttonProps = isSortable
      ? {
          role: "button",
          onClick: this.handleClick,
        }
      : {};

    const scope = colSpan ? "colgroup" : "col";

    return (
      <Container
        {...rest}
        key={id}
        alignment={align || "left"}
        sortable={isSortable}
        colSpan={colSpan}
        scope={scope}
        width={width}
        onClick={this.handleClick}
        data-tableheadercell-sortable={isSortable}
        data-qa-table-header-cell=""
        data-qa-table-header-cell-sortdirection={sortDirection}
        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={color}
        aria-sort={ariaSort}
      >
        {isSortable ? (
          <UnstyledButton {...buttonProps}>
            <div aria-live="polite" role="status">
              {children || content}
              {!children && this.getSortIcon(id === sortId, ariaSort)}
            </div>
          </UnstyledButton>
        ) : (
          children || content
        )}
      </Container>
    );
  }
}

export default TableHeaderCell;
